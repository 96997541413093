import { request } from "@/utils/request"

export function createOrder(data) {
    return request({
        url: "api/review/create",
        method: "post",
        data
    })
}

export function recheck(data) {
    return request({
        url: "api/traffic/recheck",
        method: "post",
        data
    })
}

export function getLogsOrder(params) {
    return request({
        url: `api/review/list?${params}`,
        method: "get"
    })
}

export function getLogsOrderDetailById(id) {
    return request({
        url: `api/traffic/get-report?ads_id=${id}`,
        method: "get"
    })
}
