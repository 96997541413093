<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="panel = 'form'"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="panel = 'logs'"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>

        <div class="tab-content mt-3" id="myTabContent">
            <div
                v-if="panel === 'form'"
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
            >
                <div class="row">
                    <div class="col-md-8">
                        <ReviewForm :type="type" :price="priceSingle" />
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>- Hệ thống sử dụng tài khoản người VN</p>
                                <p>
                                    - Mỗi id có thể
                                    <span class="font-bold">Buff tối đa 10 lần trên hệ thống để tránh Spam</span>, max
                                    50k, số lượng càng lớn thì thời gian chạy càng lâu
                                </p>
                                <p>
                                    - Thời gian tăng {{ type }} tính từ ngày bắt đầu cho đến ngày kết thúc job, tối đa
                                    là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng sẽ tự động hoàn lại số tiền
                                    seeding chưa tăng cho bạn <b class="font-bold">trong vòng từ 2 đến 4 ngày</b>
                                </p>
                                <p>- Vui lòng lấy đúng link trang cá nhân và không nhập id khác</p>
                                <p>
                                    - Check kỹ job tránh tạo nhầm, tính năng đang trong giai đoạn thử nghiệm nên sẽ
                                    không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <ReviewMapLogs v-if="type.includes('map')" :type="type" />
                <ReviewAppLogs v-else :type="type" />
            </div>
        </div>
    </div>
</template>

<script>
import ReviewMapLogs from "./map-logs.vue"
import ReviewAppLogs from "./app-logs.vue"
import ReviewForm from "./form.vue"

const TOOL_ID = 100

export default {
    name: "ReviewOrder",

    components: { ReviewForm, ReviewMapLogs, ReviewAppLogs },

    data: () => ({ panel: "form" }),

    computed: {
        type() {
            return this.$route.meta.type
        },
        tool() {
            return this.$store.state.tools.tools.find(tool => tool.id === TOOL_ID)
        },
        price() {
            return this.tool?.prices.find(price => price.package_name.includes(this.type))?.price || 0
        },
        prices() {
            return this.tool?.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        }
    }
}
</script>
