<template>
    <div>
        <div class="row form-group">
            <div class="col-md-3 col-12">
                <h6 class="bold mb-0">
                    {{ type.includes("map") ? "Nhập link map:" : "Nhập link ứng dụng" }}
                </h6>
            </div>
            <div class="col-md col-12">
                <input
                    v-model="link"
                    type="text"
                    class="form-control input light"
                    :placeholder="
                        type.includes('map')
                            ? 'Ví dụ https://www.google.com/maps/place/Notre+Dame+Cathedral+of+Saigon/@10.7797908,106.6968302,17z/data=!3m1!4b1!4m6!3m5!1s0x31752f37e6d82451:0xe84f59936ced5b45!8m2!3d10.7797855!4d106.6990189!16s%2Fm%2F027b16m'
                            : 'Ví dụ https://apps.apple.com/vn/app/vieon-films-sport-show-tv/id1357819721'
                    "
                />
            </div>
        </div>

        <div class="row form-group">
            <div class="col-md-3 col-12">
                <h6 class="bold mb-0">Nội dung đánh giá:</h6>
            </div>
            <div class="col-md col-12">
                <div>
                    <textarea
                        class="form-control input-light"
                        rows="10"
                        v-model="messages"
                        placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 bình luận"
                    ></textarea>
                    <span class="badge badge-primary text-white py-1 float-right">Số lượng: {{ quantity }}</span>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-3 col-12">
                <h6 class="bold mb-0">Ghi chú:</h6>
            </div>
            <div class="col-md col-12 text-right">
                <textarea
                    class="form-control input-light"
                    rows="2"
                    v-model="notes"
                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                ></textarea>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-12 text-center bold">
                <div class="card card-info">
                    <div class="card-body">
                        <h5 class="font-bold">
                            Tổng:
                            <span class="bold">
                                <number
                                    ref="number1"
                                    :from="0"
                                    :to="totalPrice"
                                    :format="numberAnimationFormat"
                                    :duration="1"
                                    :delay="0"
                                    easing="Power1.easeOut"
                                />
                                {{ currency }}
                            </span>
                        </h5>
                        <h6 class="mb-0">
                            Bạn sẽ buff <span class="bold">{{ quantity }} {{ type }}</span> với giá
                            <span class="green bold">{{ price | formatNumber }}</span>
                            {{ currency }} / {{ type }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-12 text-center bold">
                <button @click="onSubmit" class="btn btn-block btn-dark-blue btn-lg btn-rounded bold" type="submit">
                    Tạo tiến trình
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { numberAnimationFormat, checkBadWords } from "@/helpers"
import { catchError } from "@/helpers"
import { createOrder } from "@/api/services/reviews"

export default {
    props: {
        type: { type: String, required: true },
        price: { type: Number, required: true }
    },

    data: () => ({ link: "", messages: "", notes: "" }),

    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        listMessages() {
            return this.messages.match(/.+/g) || []
        },
        quantity() {
            return this.listMessages.length
        },
        totalPrice() {
            return this.price * this.quantity
        }
    },

    methods: {
        numberAnimationFormat,
        onSubmit() {
            const isValid = this.validate()
            if (!isValid) return

            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${this.type.toUpperCase()} với giá: ${this.price} ${
                    this.currency
                }/1 ${this.type.toUpperCase()} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.handleSubmit()
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        validate() {
            if (!this.link) {
                return this.$toastr.error("Vui lòng nhập link map")
            }

            if (this.listMessages.length < 1) {
                return this.$toastr.error("Vui lòng nhập nội dung đánh giá")
            }

            if (checkBadWords(this.messages)) {
                return this.$swal(
                    "Lỗi",
                    "Nội dung bình luận của bạn vi phạm tiêu chuẩn cộng đồng, hãy nhập lại nội dung cần bình luận!",
                    "error"
                )
            }

            return true
        },
        async handleSubmit() {
            const body = {
                link: this.link,
                list_messages: this.listMessages,
                image: "",
                quantity: this.quantity,
                type: this.type,
                notes: this.notes
            }

            const response = await createOrder(body)
            if (!response) {
                this.$toastr.error("Đã có lỗi xảy ra, vui lòng thử lại sau")
                return
            }
            if (response.success) {
                this.$toastr.success(response.message || "Tạo tiến trình thành công !")
            } else {
                this.$toastr.error(catchError(response))
            }
        }
    }
}
</script>
