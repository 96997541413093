<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getLogsOrder"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id_search"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getLogsOrder" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <datatable stt="true" :columns="columnsTableLogs" :rows="data">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" slot-scope="props" class="text-center" style="vertical-align: middle">
                <div class="btn-group dropright">
                    <button
                        type="button"
                        class="btn bg-primary text-white btn-xs dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Thao Tác
                    </button>
                    <div class="dropdown-menu">
                        <div class="dropdown-item" v-if="props.row.status != 2">
                            <button
                                @click="onRecheck(props.row, 'confirm')"
                                class="w-100 btn bg-violet-1 text-white btn-xs"
                            >
                                <i class="far fa-check-circle"></i> Duyệt Chạy
                            </button>
                        </div>
                        <div class="dropdown-item" v-if="props.row.status != 2">
                            <button
                                @click="onRecheck(props.row, 'hidden')"
                                class="w-100 btn bg-danger text-white btn-xs"
                            >
                                <i class="far fa-trash-alt"></i> Tạm Dừng
                            </button>
                        </div>
                        <div class="dropdown-item" v-if="props.row.status != 3">
                            <button @click="onRecheck(props.row, 'done')" class="w-100 btn bg-info text-white btn-xs">
                                <i class="far fa-check-circle"></i> Set Hoàn Thành
                            </button>
                        </div>
                        <div class="dropdown-item" v-if="props.row.status != 2">
                            <button
                                @click="onRecheck(props.row, 'warranty')"
                                class="w-100 btn bg-info text-white btn-xs"
                            >
                                <i class="far fa-check-alt"></i> Kích bảo hành
                            </button>
                        </div>
                        <div class="dropdown-item" v-if="props.row.is_removed != 1">
                            <button
                                @click="onRecheck(props.row, 'remove')"
                                class="w-100 btn bg-danger text-white btn-xs"
                            >
                                <i class="far fa-trash-alt"></i> Hủy Đơn
                            </button>
                        </div>
                        <div class="dropdown-item">
                            <button @click="onShowReport(props.row)" class="w-100 btn bg-warning text-white btn-xs">
                                <i class="fas fa-info-circle"></i> Xem báo cáo
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </datatable>

        <div
            class="modal fade"
            id="reportDetailModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Báo cáo kết quả chạy chiến dịch</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <datatable
                            class="box-shadow"
                            :title="'Lịch sử'"
                            :stt="true"
                            :columns="columnsTableReportLogs"
                            :rows="reports"
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" :class="['btn btn-sm bg-warning text-white px-4']" data-dismiss="modal">
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { catchError } from "@/helpers"
import { removeOrder, getLogsOrder, getLogsOrderDetailById, recheck } from "@/api/services/reviews"
import datatable from "@/components/datatable/datatable"

export default {
    name: "ReviewLogs",

    components: { datatable },

    props: {
        type: { type: String, required: true }
    },

    data() {
        return {
            object_id_search: "",
            limit: 100,
            data: [],
            columnsTableLogs: [
                { choose: true, label: "Id", field: "id", admin: true },
                { choose: true, label: "Username", field: "username" },
                { choose: true, label: "Tên", field: "name", instagram_link: true, link: "link" },
                { choose: true, label: "Business Id", field: "object_id" },
                { choose: true, label: "Review Count", field: "review_count" },
                { choose: true, label: "Rating", field: "rating" },
                { choose: true, label: "Hidden", field: "is_hidden", admin: true },
                { choose: true, label: "Viewer", field: "viewer", admin: true },
                { choose: true, label: "Error", field: "count_error", admin: true },
                { choose: true, label: "Tổng", field: "quantity", numeric: true },
                { choose: true, label: "Tổng tiền", field: "price", numeric: true },
                { choose: true, label: "Đã chạy", field: "count_is_run", numeric: true },
                { choose: true, label: "Khởi tạo", field: "created_at", timeago: true },
                { choose: true, label: "Cập nhật", field: "updated_at" },
                { choose: true, label: "Trạng thái", field: "status_text_html", html: true }
            ],
            reports: [],
            columnsTableReportLogs: [
                { choose: true, label: "Username", field: "username", admin: true },
                { choose: true, label: "Id", field: "id", admin: true },
                { choose: true, label: "Tên user thực thiện", field: "author_name" },
                { choose: true, label: "Nội dung", field: "message", instagram_link: true, link: "review_link" },
                { choose: true, label: "Rating", field: "rating" },
                { choose: true, label: "Ngày Thực Hiện", field: "created_at" }
            ]
        }
    },

    computed: {
        currency() {
            return this.$store.state.site.site.currency
        }
    },

    created() {
        this.getLogsOrder()
    },

    watch: {
        type() {
            this.getLogsOrder()
        }
    },

    methods: {
        async getLogsOrder() {
            this.data = []

            const param =
                `limit=${this.limit}&type=${this.type}` +
                (this.object_id_search ? `&object_id=${this.object_id_search}` : "")
            const data = await getLogsOrder(param)
            if (!data.data) return

            this.data = data.data.map(item => {
                const total = item.price_per * item.quantity_origin
                item.name = item.object_data.name || null
                item.rating = item.object_data.rating || 0
                item.review_count = item.object_data.review_count || 0
                const status_text_html =
                    item.object_not_exist || item.is_hidden || (item.is_refund && item.count_refund > 0)
                        ? `<span class="py-1 badge badge-danger"><i class="material-icons icon-log">info</i>${item.status_message}</span>`
                        : item.status !== 2
                        ? `<span class="py-1 badge badge-yellow"><i class="material-icons icon-log">autorenew</i>${item.status_message}</span>`
                        : `<span class="py-1 badge badge-success"><i class="material-icons icon-log">beenhere</i>${item.status_message}</span>`
                return { ...item, total, status_text_html }
            })
        },

        async removeOrder(item) {
            let cancel = false
            await this.$swal({
                title: `Xác nhận`,
                html: `Bạn có chắc chắn hủy ${item.type.toUpperCase()} ID: ${
                    item.object_id
                } ? Sau khi hủy bạn sẽ mất số lượng ${item.type.toUpperCase()} chưa tăng và trừ thêm 5.000 ${
                    this.currency
                } phí để tránh Spam !`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    cancel = true
                }
            })
            if (cancel) {
                let param = {
                    id: item.id
                }
                let data = await removeOrder(param)
                if (data.status === 200 && data.success) {
                    this.$toastr.success("ID Seeding: " + item.object_id + " - " + data.message)
                    this.data = this.data.map(log => {
                        if (log.id == item.id) {
                            item.is_removed = 1
                        }
                        return log
                    })
                } else this.$toastr.error("ID Seeding: " + item.object_id + " - " + catchError(data))
            }
        },

        async onRecheck(row, action) {
            const body = { ads_id: row.id, action }
            const response = await recheck(body)
            if (!response) {
                return this.$toastr.error("Đã có lỗi xảy ra, vui lòng thử lại sau")
            }
            if (response.success) {
                this.$toastr.success(response.message || "Thao tác thành công !")
            } else {
                this.$toastr.error(catchError(response))
            }
        },

        async onShowReport(row) {
            this.reports = []
            const data = await getLogsOrderDetailById(row.id)
            if (data.data) {
                this.reports = data.data.map(order => {
                    order.color = order.status === 0
                    return order
                })
            }
            $("#reportDetailModal").modal("show")
        }
    }
}
</script>
